// Useful docs for auth0 are available here https://auth0.github.io/auth0-spa-js/
// They're a little hard to find via Google.

import createAuth0Client, {
  Auth0Client,
  User,
  GenericError,
  AuthenticationError,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
} from "@auth0/auth0-spa-js";
import store from "./store";
import * as _ from "lodash";
import { addBreadcrumb, captureException } from "./sentry";
import { BASE_URL } from "./constants";
import { getDomainFromEmail } from "./util/string";
import orderHistoryStore from "./sub-stores/order-history";

// Where to send user after logout
const LOGOUT_URL = import.meta.env.VITE_AUTH0_LOGOUT_URL ?? location.origin;

// Public domains for social providers supported.
// Use to check if user email domain is in a public domain we support.
// If more social connections added, add its entry here along with its public emails list.
const SUPPORTED_SOCIAL_PROVIDERS_PUBLIC_DOMAINS = {
  google: ["gmail.com", "googlemail.com"],
};

// Start of custom error message we've added by Auth0 Flow function
const ERROR_EMAIL_UNVERIFIED = "email-unverified:";

export class Auth0Error extends Error {
  constructor(message: string) {
    super(message);
    this.name = "Auth0Error";
  }
}

export class Auth0LoginError extends Auth0Error {
  constructor(message: string) {
    super(message);
    this.name = "Auth0LoginError";
  }
}

export class Auth0EmailVerificationRequiredError extends Auth0LoginError {
  emailAddress: string;

  constructor(message: string, emailAddress: string) {
    super(message);
    this.name = "Auth0EmailVerificationRequiredError";
    this.emailAddress = emailAddress;
  }
}

export class Auth0UserBlockedError extends Auth0LoginError {
  constructor(message: string) {
    super(message);
    this.name = "Auth0UserBlockedError";
  }
}

export class Auth0ExpirationTimeClaimError extends Auth0LoginError {
  constructor(message: string) {
    super(message);
    this.name = "Auth0ExpirationTimeClaimError";
  }
}

// Thrown when state parameter stored by auth0 in localStorage at login, cannot be validated at callback
// after response from the server is returned.
// Normally happens when the callback url is hit manually by user either directly opened in browser,
// through back button, or refreshing on the callback page etc.
export class Auth0InvalidStateError extends Auth0Error {
  constructor(message: string) {
    super(message);
    this.name = "Auth0InvalidStateError";
  }
}

// Thrown when a request is made for a token but either there is no current
// session or the session has expired.
export class Auth0LoginRequired extends Auth0Error {
  constructor(message: string) {
    super(message);
    this.name = "Auth0LoginRequired";
  }
}

let instance: Auth0Client;

const clientPromise = createAuth0Client({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE, // You don't get a JWT without this.
  redirect_uri: `${BASE_URL}/auth/callback`,
  cacheLocation: "localstorage",
  useRefreshTokens: true,
})
  .then((auth0) => {
    instance = auth0;
  })
  .catch((e) => {
    console.log(e);
  });

// Get client, waiting until it's available
export async function getInstance(): Promise<Auth0Client> {
  await clientPromise;
  return instance;
}

// Redirect user to auth0 login
export async function doLogin(loginOptions: RedirectLoginOptions = {}) {
  const auth0 = await getInstance();
  await auth0.loginWithRedirect(loginOptions);
}

// Handle the auth0 login callback, throws an Auth0LoginError if there's an error
export async function handleLoginCallback(): Promise<void> {
  const auth0 = await getInstance();
  let redirectResult;
  try {
    redirectResult = await auth0.handleRedirectCallback();
  } catch (e: any) {
    addBreadcrumb({ message: "Unable to handle authentication callback" });

    if (e instanceof AuthenticationError) {
      if (_.startsWith(e.error_description, ERROR_EMAIL_UNVERIFIED)) {
        const emailAddress = _.split(e.error_description, ":", 2)[1];
        throw new Auth0EmailVerificationRequiredError(
          e.error_description,
          emailAddress
        );
      }

      if (e.error_description === "user is blocked") {
        throw new Auth0UserBlockedError(e.error_description);
      }

      // Unknown error, capture it
      captureException(e);
      throw new Auth0LoginError(e.error_description);
    } else {
      const errorMessage = e.message || "";
      //this (exp) claim error can occur if user's local machine time is out of sync with the actual time.
      if (
        errorMessage.includes(
          "Expiration Time (exp) claim error in the ID token"
        )
      ) {
        throw new Auth0ExpirationTimeClaimError(errorMessage);
      }

      if (errorMessage === "Invalid state") {
        throw new Auth0InvalidStateError(errorMessage);
      }

      // Not a handled error type, capture it
      captureException(e);
      throw new Auth0LoginError(`${e}`);
    }
  }
}

// Restores app state from auth0 on initial load, returns true if user is authenticated
export async function restoreAuthState(): Promise<boolean> {
  console.debug("Restoring auth state");
  const auth0 = await getInstance();
  const loggedIn = await auth0.isAuthenticated();
  if (loggedIn) {
    const user = await auth0.getUser();
    console.debug("User is authenticated: " + user?.sub);
    if (!user) {
      throw new Error("Auth0 did not return a valid user");
    }
    return true;
  } else {
    // User is unauthenticated, caller will likely want to send to a login page
    return false;
  }
}

export async function isUserAuthenticated() {
  const auth0 = await getInstance();
  return await auth0.isAuthenticated();
}

// Handle logout, clears anything that needs clearing and sends user to auth0 logout page
export async function doLogout() {
  const auth0 = await getInstance();
  orderHistoryStore.clearStorage();
  store.clearCustomer();
  store.clearIsStaff();
  auth0.logout({ returnTo: LOGOUT_URL || BASE_URL });
}

// Get a JWT token, will force a logout if auth is no longer valid
export async function getJwtToken(
  options?: GetTokenSilentlyOptions
): Promise<string> {
  const auth0 = await getInstance();
  try {
    const token = await auth0.getTokenSilently(options);
    store.setStatePermissionsFromAuth0JwtToken(token);
    return token;
  } catch (error) {
    if (error instanceof GenericError) {
      if (error.error === "login_required" || error.error === "mfa_required") {
        await doLogin();
        // Throw exception so the request to our backend doesn't happen and we don't get a 401
        throw new Auth0LoginRequired("Login required");
      }
    }
    // TODO: Monitor what exceptions are thrown here and handle them appropriately, then reduce the logging
    captureException(error);
    throw new Auth0Error(`${error}`);
  }
}

export async function checkIfUserAuthenticated(): Promise<boolean> {
  const auth0 = await getInstance();
  return await auth0.isAuthenticated();
}

export async function getUser(): Promise<User | undefined> {
  const instance = getInstance();
  return (await instance).getUser();
}

/**
 * NOTE: This assumes database or username/password login using public email restriction is handled at auth0 level using actions/hooks/rules etc.
 * This checks if user email domain is in public email domains of the social auth providers we support.
 */
export async function userLoggedInWithPublicEmail() {
  const user = await getUser();
  const domain = getDomainFromEmail(user?.email || "");
  const notAllowedDomains = Object.values(
    SUPPORTED_SOCIAL_PROVIDERS_PUBLIC_DOMAINS
  ).reduce<string[]>((prev, curr) => {
    return [...prev, ...curr];
  }, []);
  if (notAllowedDomains.includes(domain)) {
    return true;
  }
  return false;
}
