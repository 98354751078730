<script setup lang="ts">
import { onMounted } from "vue";
import FullPageLoader from "@two-ui/components/FullPageLoader.vue";
import { useRoute } from "vue-router";
import { doLogin } from "@two-ui/auth0";
import GenericError from "@two-ui/components/GenericError.vue";
import { assert } from "@two-ui/util/typescript";
const {
  query: { email: email_address },
} = useRoute();

onMounted(() => {
  assert(
    typeof email_address === "string",
    "Please use the link in your invite email"
  );
  // redirect to auth0 signup, passing email from the token.
  doLogin({ screen_hint: "signup", login_hint: email_address });
});
</script>

<template>
  <FullPageLoader>Please wait…</FullPageLoader>
</template>
